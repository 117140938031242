<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="moduleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching Module Data
      </h4>
      <div class="alert-body">
        No module found with this Module Id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'system-settings-modules-list'}"
        >
          Modules List
        </b-link>
        for other Modules.
      </div>
    </b-alert>

    <template v-if="moduleData">
      <b-row>
        <b-col cols="12">
          <b-card v-if="moduleData && moduleData.description">
            <module-view-info :module-data="moduleData" />
          </b-card>

          <menus-list
            v-if="moduleData.menus && moduleData.menus.length > 0"
            :title="'Menus'"
            :menus="moduleData.menus"
          />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BCard,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import moduleStoreModule from '../moduleStoreModule'
import ModuleViewInfo from './ModuleViewInfo.vue'
import useModules from '../useModules'
import MenusList from '../../menus/menus-list/MenusList.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,

    // Local Components
    ModuleViewInfo,
    MenusList,
  },
  setup() {
    const MODULE_APP_STORE_MODULE_NAME = 'app-module'

    // Register module
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)

    const {
      fetchModule,

      moduleData,
    } = useModules()

    fetchModule(router.currentRoute.params.id)

    return {
      moduleData,
    }
  },
}
</script>
