<template>
  <b-row v-if="moduleData">
    <b-col sm="8">
      <h4 class="text-capitalize font-weight-bold">
        {{ moduleData.description }}
      </h4>
      <b-badge
        v-if="moduleData.status"
        pill
        :variant="`light-${resolveStatusVariant(moduleData.status)}`"
        class="text-capitalize"
      >
        {{ resolveStatus(moduleData.status) }}
      </b-badge>
    </b-col>

    <b-col v-if="$ability.can('E', 'Modules & Menus')">
      <b-button
        size="sm"
        :to="{ name: 'system-settings-modules-edit', params: { id: moduleData.moduleId } }"
        variant="primary"
        class="float-right"
      >
        Edit
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BRow, BCol, BBadge,
} from 'bootstrap-vue'
// import useModulesList from '../modules-list/useModulesList'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BButton, BRow, BCol, BBadge,
  },
  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // const {
    //   resolveStatus,
    //   resolveStatusVariant,
    // } = useModulesList()

    return {
      resolveStatus,
      resolveStatusVariant,
    }
  },
}
</script>
